<template>
  <div>
    <el-breadcrumb style="margin: 0 0 10px 10px" separator="/">
      <el-breadcrumb-item>菜品管理</el-breadcrumb-item>
      <el-breadcrumb-item>二维码列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">危废管理</div>
      <div class="xian"></div> -->
      <div class="list_box">
        <div class="tt1">二维码列表</div>
        <!-- <div>
          
          <el-button size="small" type="add" icon="el-icon-circle-plus-outline" @click="add"
            >生码</el-button
          >
        </div> -->
      </div>
      <div class="int_box">
        <span>日期时间：</span>
        <el-date-picker
        value-format="yyyy-MM-dd HH:mm:ss"
        v-model="time"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
        </el-date-picker>
        <!-- <span style="margin-left:50px">状态：</span>
        <el-radio-group v-model="status">
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="1">已用完</el-radio>
            <el-radio :label="2">未用完</el-radio>
        </el-radio-group> -->
        <el-button size="small" style="margin-left:50px" type="cha" icon="el-icon-search" @click="chaxun"
            >查询</el-button
          >
      </div>
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="userList"
          style="width: 100%"
          stripe
        >
          <el-table-column
            label="序号"
            type="index"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
          ></el-table-column>
          <el-table-column
            prop="num"
            label="个数"
          ></el-table-column>
          <!-- <el-table-column
            prop="yet_num"
            label="使用个数"
          ></el-table-column> -->
          <!-- <el-table-column
            label="二维码状态"
          >
            <template v-slot="scope">
              <div v-if="scope.row.compress_status == 0">未生成</div>
              <div v-if="scope.row.compress_status == 3">已生成</div>
              <div v-if="scope.row.compress_status == 1">生成中</div>
              <div v-if="scope.row.compress_status == 2">生成中</div>
            </template>
          </el-table-column> -->
          <el-table-column
            width="180"
            prop="create_at"
            label="创建时间"
          ></el-table-column>
          <el-table-column width="200px" label="操作">
            <template v-slot="scope">
              <!-- <el-button
                size="mini"
                type="success"
                icon="iconfont icon-peizhi"
                @click="peizhi(scope.row.id)"
                >使用配置</el-button
              > -->
              <!-- <el-button
                size="mini"
                type="primary"
                icon="iconfont"
                @click="codeList(scope.row.id)"
                >码列表</el-button
              > -->
              <!-- <el-button
                v-if="scope.row.create_status == 2"
                size="mini"
                type="success"
                icon="iconfont"
                @click="xiazai(scope.row.id)"
                >下载码包</el-button
              > -->
              <!-- <el-button
                v-if="scope.row.compress_status == 0"
                size="mini"
                type="primary"
                icon="iconfont"
                @click="scewm(scope.row.id)"
                >生成二维码</el-button
              > -->
              <el-button
                v-if="scope.row.compress_status == 3 && scope.row.create_status == 2"
                size="mini"
                type="success"
                icon="iconfont"
                @click="xzewm(scope.row.compress_files_path)"
                >下载二维码</el-button
              >
              <div v-else>二维码生成中，请稍后查看</div>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 15, 20]"
          :page-size="pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <!-- 使用配置 -->
      <el-dialog
        title="使用配置"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible1"
        width="1100px"
        :before-close="handleClose1"
      >
        <div class="xian2"></div>
        <el-form
          ref="form1"
          :rules="rules1"
          :model="addform1"
          label-width="200px"
        >
            <el-form-item label="配置方式：">
              <el-radio-group v-model="radio" @change="changeRadio">
                <el-radio :label="1">全部码包</el-radio>
                <el-radio :label="2">码段</el-radio>
                <el-radio :label="3">码号</el-radio>
                
            </el-radio-group>
            </el-form-item>
            <!-- <el-form-item v-if="radio===1" label="全部码包：" prop="isall">
              <el-input class="int" v-model="addform1.isall"></el-input>
            </el-form-item> -->
            <el-form-item v-if="radio===2" label="开始码：" prop="startcode">
              <el-input class="int" v-model="addform1.startcode"></el-input>
            </el-form-item>
             <el-form-item v-if="radio===2" label="结束码：" prop="endcode">
              <el-input class="int" v-model="addform1.endcode"></el-input>
            </el-form-item>
            <el-form-item v-if="radio===3" label="码号：" prop="codestr">
              <el-input type="textarea" class="int" :autosize="{ minRows: 3}" v-model="addform1.codestr"></el-input>
            </el-form-item>
            <el-form-item label="产品：" prop="goodsid">
              <el-select v-model="addform1.goodsid" placeholder="请选择">
                    <el-option
                    v-for="item in goodsList"
                    :key="item.id"
                    :label="item.product_name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="单位重量(吨)：" prop="weight">
              <el-input class="int" v-model="addform1.weight"></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="生产批次：" prop="usetime">
              <el-input class="int" v-model="addform1.usetime"></el-input>
            </el-form-item> -->
        </el-form>
        <div class="btn_box">
          <el-button type="cha" class="add_btn111" @click="addpeizhi"
            >确定</el-button
          >
        </div>
      </el-dialog>
      
      <!-- 码列表 -->
      <el-dialog
        title="码列表"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible2"
        width="1100px"
        :before-close="handleClose2"
      >
        <div class="xian2"></div>
        <div class="int_box1">
        <span>流水号：</span>
        <el-input
            class="int_width"
            v-model="codestr"
            placeholder="请输入流水号"
            clearable
          ></el-input>
        <el-button size="small" style="margin-left:50px" type="cha" icon="el-icon-search" @click="chaxun1"
            >查询</el-button
          >
      </div>
        <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="List"
          style="width: 100%"
          stripe
        > 
          <el-table-column
            type="index"
            width="60"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="no"
            label="流水号"
          ></el-table-column>
          <el-table-column
            prop="captcha"
            label="防伪码"
          ></el-table-column>
          <el-table-column
            width="200"
            prop="complete_link"
            label="链接"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="link"
            label="状态"
          >
            <template v-slot="scope">
              <div v-if="scope.row.status ===1">未激活</div>
              <div v-if="scope.row.status ===2">激活</div>
              <div v-if="scope.row.status ===3">失效</div>
            </template>
          </el-table-column>
           <el-table-column
            prop="product_name"
            label="对应产品"
          ></el-table-column>
          <!-- <el-table-column
            align="center"
            prop="unit_weight"
            label="单位重量(吨)"
          ></el-table-column> -->
          <el-table-column label="操作" width="80">
            <template v-slot="scope">
              <el-button
                v-if="scope.row.status != 3"
                size="mini"
                type="warning"
                icon="iconfont"
                @click="chexiao(scope.row.no)"
                >禁用</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="page2"
          :page-sizes="[10, 15, 20]"
          :page-size="pageNumber2"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total2"
        ></el-pagination>
      </div>
      </el-dialog>
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
  data() {
    return {
      page: 1,
      pageNumber: 10,
      starttime: '',
      endtime: '',
      productid: '',
      status: 0,
      time: [],
      userList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },
      dialogVisible: false,
      addform: {
        name: '',
        bsm:'',
        num: ''
      },
      rules: {
        name: [{ required: true, message: '请输入码包名称', trigger: 'blur' }],
        bsm: [{ required: true, message: '请输入标识码', trigger: 'blur' }],
        num: [{ required: true, message: '请输入数量', trigger: 'blur' }]
      },

        // 使用配置参数
        dialogVisible1:false,
        addform1: {
        packageid: '',
        startcode: '',
        endcode: '',
        codestr: '',
        goodsid: '',
        // weight: '1',
        // usetime: '',
        isall: 1,
      },
      rules1: {
        startcode: [{ required: true, message: '请输入开始码', trigger: 'blur' }],
        endcode: [{ required: true, message: '请输入结束码', trigger: 'blur' }],
        codestr: [{ required: true, message: '请输入码号', trigger: 'blur' }],
        goodsid: [{ required: true, message: '请选择商品', trigger: 'blur' }],
        // weight: [{ required: true, message: '请输入单位重量', trigger: 'blur' }],
        // usetime: [{ required: true, message: '请输入生产批次', trigger: 'blur' }]
      },
      radio: 1,
    //   产品列表
    goodsList: [],
    // 码列表 参数
    dialogVisible2: false,
    page2: 1,
    pageNumber2: 10,
    total2: 0,
    List: [],
    ids: '',
    isLoading: false,
    fullPage: true,
    // 流水号
    codestr: ''


    
    }
  },
  created() {
    this.productid = this.$route.query.product_id
    this.user()
  },
  components: {
    Loading
  },
  methods: {
    onCancel() {
      this.$message.error('您取消了数据加载')
    },
    handleSizeChange(val) {
      this.pageNumber = val
      this.user()
    },
    handleCurrentChange(val) {
      this.page = val
      this.user()
    },
    handleSizeChange2(val) {
      this.pageNumber2 = val
      this.qrcodeList()
    },
    handleCurrentChange2(val) {
      this.page2 = val
      this.qrcodeList()
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.addform= {
        name: '',
        bsm: '',
        num: '',
      }
    },
    handleClose1() {
      this.dialogVisible1 = false
      this.$refs.form1.resetFields()
      this.addform1 = {
        packageid: '',
        startcode: '',
        endcode: '',
        codestr: '',
        goodsid: '',
        // weight: '1',
        // usetime: '',
        isall: 1,
      }
      this.radio = 1
    },
    handleClose2() {
      this.dialogVisible2 = false
      this.List = []
      this.page2 = 1
    },
    // 配置选择
    changeRadio() {
      console.log(this.radio)
      console.log(this.addform1.isall)
      if(this.radio !== 1) {
        this.addform1.isall = ''
      }else {
        this.addform1.isall = 1
      }
    },

    // 码包列表
    async user() {
      const { data: res } = await this.$http.get('api/qrcode/index', {
        params: {
          page: this.page,
          pageNumber: this.pageNumber,
          starttime: this.time ? this.time[0] : '',
          endtime: this.time ? this.time[1] : '',
          productid: this.productid
        },
      })
      console.log(11,res)
      this.userList = res.data.rows
      this.total = res.data.total
    },
    // 查询
    chaxun() {
      this.page = 1
      this.user()
    },
    chaxun1() {
      this.page2 = 1
      this.qrcodeList()
    },
    // 点击添加按钮，弹出对话框
    add() {
      this.dialogVisible = true
    },
   
    // 添加
    addWaste() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')
       
        const { data: res } = await this.$http.post('api/qrcode/create', this.addform)
        this.dialogVisible = false

        this.qrcode(res.data.packageid)
        
      })
    },
    // 生码查询
    async qrcode(id) {
      this.isLoading = true
      const { data: res } = await this.$http.get('api/qrcode/status', {
        params: {
          packageid: id,
        },
      })
      if (res.code != 200) return this.$message.error(res.msg)

      if(res.data === 0) {
        
        this.qrcode(id)
      }else{
        this.isLoading = false
        this.$message.success(res.msg)
        
        this.$refs.form.resetFields()
        this.page = 1
        this.user()
      }
        
    },
    // 点击使用配置
    peizhi(id) {
        this.dialogVisible1 = true
        this.addform1.packageid = id
        this.goods()
    },
    // 产品列表
    async goods() {
      const { data: res } = await this.$http.get('api/goods/index', {
        params: {
          page: 1,
          size: 200,
        },
      })
      console.log(11, res)
      this.goodsList = res.data.rows
    },
    // 使用配置
    addpeizhi() {
        this.$refs.form1.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')
        
        const { data: res } = await this.$http.post(
          'api/qrcode/codeuse',
          this.addform1
        )
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.dialogVisible1 = false
        this.$refs.form1.resetFields()
        this.user()
        })
    },
    // 点击码列表
    codeList(id) {
      this.dialogVisible2 = true
      this.ids = id
      this.qrcodeList()
    },
    // 码列表
    async qrcodeList() {
      const { data: res } = await this.$http.get('api/qrcode/list', {
        params: {
          page: this.page2,
          pageNumber: this.pageNumber2,
          packageid: this.ids,
          codestr: this.codestr
        }
      })
      console.log(11,res)
      this.List = res.data.qrcode
      this.total2 = res.data.count
    },
    // 禁用
    async chexiao(no) {
      const { data: res} = await this.$http.get('api/qrcode/disablecode', {
        params: {
          codestr: no
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.qrcodeList()
    },
    // 下载码包
    async xiazai(id) {
      const { data: res} = await this.$http.get('api/qrcode/download', {
        params: {
          package_id: id
        }
      })
      if (res.code == 400) return this.$message.error(res.msg)
      window.open(this.$URL + 'api/qrcode/download?package_id=' + id + '&token=' + localStorage.getItem('jd_token'))
    },
    // 生成二维码
    async scewm(id) {
      this.isLoading = true
      const { data: res} = await this.$http.get('api/qrcode/addcompress', {
        params: {
          packageid: id
        }
      })
      this.isLoading = false
      if (res.code != 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
    },
    // 下载二维码
    xzewm(url) {
      window.open(this.$URL + url)
    }
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 13px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 40px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box {
    padding: 20px 1px;
  }
  .int_box1 {
    padding: 0 1px 25px;
  }
  .int_width {
    width: 240px;
    margin-right: 40px;
  }
  .int_width1 {
    width: 240px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 10px;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
.int {
    width: 400px;
}
  
  .add_btn {
    margin-left: 800px;
  }
  .danger_btn {
    margin-left: 20px;
  }
  .btn_box {
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
  .add_btn111 {
    width: 140px;
  }
 
 
}
</style>
